import React from "react";
import "./RanksOverView.scss";
import "./RanksOverView.css";
import { Rank } from "../../Models/RankOption";

type MyState = { products: Rank[] };
type MyProps = {};
export class ShowRanks extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      products: [
        {
          price: "4.50",
          name: "VIP Rank",
          colors: "#fff",
          id: "444",
        } as any,
        {
          price: "7.50",
          name: "VIP+ Rank",
          colors: "#fff",
          id: "444",
        } as any,
        {
          price: "15.00",
          name: "MVP Rank",
          colors: "#fff",
          id: "444",
        } as any,
        {
          price: "30.00",
          name: "MVP+ Rank",
          colors: "#fff",
          id: "444",
        } as any,
        {
          price: "30.00",
          name: "MVP++ Rank",
          colors: "#fff",
          id: "444",
        } as any,
      ],
    };
  }

  render() {
    let { products } = this.state;
    return (
      <div className="row compare centercompare">
        <div className="col-12 mt-5 text-center">
          <table className="table">
            <thead className="thead-default">
              <tr>
                <th />
                {products.map((product) => (
                  <th key={product.id}>{product.pictureURL}</th>
                ))}
              </tr>
              <tr>
                <th />
                {products.map((product) => (
                  <th key={product.id}>{product.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="price">
                <th scope="row">Price</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.price}
                  </td>
                ))}
              </tr>
              <tr className="colors">
                <th scope="row">Color</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>
              <tr className="split">
                <td className="split-item" colSpan={products.length + 1}>
                  PvP & KitPvP
                </td>
              </tr>
              <tr className="BambooPerHour">
                <th scope="row">Bamboo Per Hour</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>
              <tr className="BambooPayment">
                <th scope="row">Bamboo</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>
              <tr className="XPKit">
                <th scope="row">XP Kit</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>

              <tr className="PermWeapons">
                <th scope="row">PermWeapons</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>
              <tr className="AirdropTimer">
                <th scope="row">Call an Airdrop</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>

              <tr className="Vault">
                <th scope="row">Vaults</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>

              <tr className="OtherVaults">
                <th scope="row">Extra Vaults</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>

              <tr className="ExtraKit">
                <th scope="row">Ghillie Suit</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>

              <tr className="EssentialKit">
                <th scope="row">Essential Kit</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>

              <tr className="ExtraEssentialKit">
                <th scope="row">Extra Essential Kit</th>
                {products.map((product) => (
                  <td key={product.id} className="text-center">
                    {product.color}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export default ShowRanks;
